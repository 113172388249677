import L from 'leaflet'

// https://github.com/mapbox/leaflet-pip
// import leafletPip from '@mapbox/leaflet-pip/leaflet-pip'

// https://leaflet.github.io/Leaflet.VectorGrid/vectorgrid-api-docs.html
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled.js'

// https://github.com/manubb/Leaflet.PixiOverlay
// import 'leaflet-pixi-overlay/L.PixiOverlay.min.js'

import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

// https://github.com/umap-project/Leaflet.i18n
import 'leaflet-i18n'

// https://github.com/Leaflet/Leaflet.markercluster
// import 'leaflet.markercluster';
// import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

// https://github.com/Leaflet/Leaflet.fullscreen
import 'leaflet.fullscreen'
import 'leaflet.fullscreen/Control.FullScreen.css'
// https://github.com/consbio/Leaflet.ZoomBox
import 'leaflet-zoombox'
import 'leaflet-zoombox/L.Control.ZoomBox.css'
// https://github.com/domoritz/leaflet-locatecontrol
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
// https://github.com/smeijer/leaflet-geosearch
import * as geoSearch from 'leaflet-geosearch'
import 'leaflet-geosearch/assets/css/leaflet.css'

// https://github.com/ljagis/leaflet-measure
import 'leaflet-measure'

import 'leaflet-measure/dist/leaflet-measure.fr.js'
import 'leaflet-measure/dist/leaflet-measure.css'

import 'leaflet-easyprint'

import { GestureHandling } from 'leaflet-gesture-handling'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'

import geojsonvt from 'geojson-vt'
import 'leaflet-geojson-vt/src/leaflet-geojson-vt.js'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('L', L)
    nuxtApp.vueApp.component('GeoSearch', geoSearch)
    // nuxtApp.vueApp.component('leafletPip', leafletPip)

    return {
        provide: {
            L,
            geoSearch,
            GestureHandling,
            geojsonvt,
            // leafletPip,
        },
    }
})
